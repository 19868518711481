import React from "react";

function About() {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-5 px-5 lg:px-14">
      <div className="space-y-6 order-2 md:order-1">
        <h2 className="font-bold text-black xl:text-4xl lg:text-2xl text-xl">
          Perchè scegliere<span className="text-primary"> EASYoratorio?</span>
        </h2>

        <p className=" text-black text-opacity-50 ">
          <span className="italic">EASYoratorio </span> è nato dall’esperienza
          sul campo di chi opera per organizzare bene e con qualità gli eventi
          promossi dall’oratorio e dalla parrocchia.{" "}
          <span className="italic">EASYoratorio </span> risponde all’esigenza di{" "}
          <strong> semplificare e velocizzare </strong> tutto ciò che richiede
          organizzare un evento. Con{" "}
          <span className="italic">EASYoratorio </span> potrai infatti gestire
          online le iscrizioni dell’attività estiva del Gr.Est., dei
          campiscuola, degli spettacoli teatrali, creare i gruppi di catechismo,
          ricevere le iscrizione per i pranzi, le feste o altri eventi sia a
          pagamento che gratuiti, avendo a portata di click l’anagrafica degli
          iscritti, i pagamenti, i gruppi, le squadre, i pullman, il controllo
          accessi.
          <span className="italic">EASYoratorio </span> è stato pensato proprio
          per <strong> facilitare la gestione </strong> delle attività,{" "}
          <strong>
            {" "}
            ottimizzare le risorse e semplificare la comunicazione{" "}
          </strong>{" "}
          tra parrocchia ed utenti (genitori, famiglie, bambini).
          <span className="hidden md:inline">
            <br />
          </span>
          <span className="italic">EASYoratorio </span> è il software gestionale
          perfetto per te e per la tua parrocchia perché è{" "}
          <strong> semplice da utilizzare </strong> e integrato con i
          <strong> braccialeti identificativi, </strong> da distribuire agli
          utenti, ti darà la possibilità di tenere sempre sotto controllo le
          attività e dire <strong> addio a tutti i documenti cartacei. </strong>
        </p>
      </div>
      <div className="flex items-center md:justify-end justify-center   order-1 md:order-2">
        <img
          src="./assets/images/about/about.webp"
          className="h-auto md:w-[80%]"
          alt="Hero"
          width={1000}
          height={1000}
        />
      </div>
    </div>
  );
}

export default About;
