import Navbar from "../common/Navbar";
import Hero from "./Hero";

export default function MainHero() {
  return (
    <>
      <Navbar />
      <Hero />
    </>
  );
}
