import React from "react";
import ServicesCard from "./ServicesCard";

export default function ServicesLayout({ name, copy }) {
  return (
    <div id="services" className="lg:py-12 pt-10 md:pt-0 px-5 lg:px-14">
      <h2 className="text-center text-secondary font-extrabold xl:text-4xl lg:text-2xl text-xl">
        Una soluzione semplice per gestire il tuo oratorio?{" "}
        <span className="text-primary">EASYoratorio!</span>
      </h2>
      <div className="my-5 lg:mt-14 mt-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-3 gap-7 mb-5">
          {copy.map((item, index) => (
            <ServicesCard key={index} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
