import React from "react";

export default function ServicesCard({ key, data }) {
  return (
    <div
      key={key}
      className="cursor-pointer hover:bg-primary group rounded-3xl bg-white shadow-md border-[1px] p-5 transition-all duration-300"
    >
      <div className="space-y-5">
        {/* Keep icon background color fixed */}
        <div className="bg-[#e7f9f7] rounded-full flex justify-center items-center w-24 h-24">
          <img src={data.img} alt={data.title} className="w-16 h-16 object-contain" />
        </div>
        <div>
          {/* Title changes color on hover */}
          <h2 className="md:text-xl font-semibold mb-3 text-primary group-hover:text-white transition-all duration-300">
            {data.title}
          </h2>
          {/* Description changes color on hover */}
          <p className="md:text-md text-sm text-black text-opacity-50 group-hover:text-gray-200 transition-all duration-300">
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
}
