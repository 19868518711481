import React, { useState } from "react";
import { LocationIcon, MailIcon } from "../../utils/Icons";
import { Link } from "react-router-dom";

function Form() {
  const [data, setData] = useState({
    name: "",
    surname: "",
    city: "",
    phone: "",
    email: "",
    institute: "",
    message: "",
  });
  const [message, setMessage] = useState({ text: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const submitUrl =
      "https://apis.easyoratorio.it/admin/user/sendContactEmail";
    try {
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok && result.status === "success") {
        setMessage({
          text: "Messaggio inviato con successo!",
          type: "success",
        });
        setData({
          name: "",
          surname: "",
          city: "",
          phone: "",
          email: "",
          institute: "",
          message: "",
        });
      } else {
        let errorText = result?.message || "Errore nell'invio del messaggio.";
        if (errorText === "Impossibile inviare l'e-mail") {
          errorText = "L'indirizzo email non è corretto";
        }

        setMessage({
          text: errorText,
          type: "error",
        });
      }
    } catch (error) {
      setMessage({
        text: error.message || "Errore di rete. Controlla la tua connessione.",
        type: "error",
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setMessage({ text: "", type: "" });
      }, 2000);
    }
  };

  return (
    <div
      id="Contact"
      className="grid md:grid-cols-2 grid-cols-1 items-center lg:gap-32 gap-10 lg:mt-16 mt-10 px-5 lg:px-14"
    >
      <div className="space-y-8 order-2 md:order-1">
        <h2 className="font-bold text-black xl:text-4xl lg:text-2xl text-xl">
          <span className="text-primary">Hai qualche domanda?</span>
          <span className="hidden md:inline">
            <br />
          </span>
          Vuoi saperne di più?
          <span className="hidden md:inline">
            <br />
          </span>
          Contattaci!
        </h2>

        {message.text && (
          <div
            className={`p-3 rounded-lg text-center text-white ${
              message.type === "success" ? "bg-primary" : "bg-red-500"
            }`}
          >
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-col md:flex-row items-center gap-5 w-full">
              <div className="space-y-2 flex-1 w-full">
                <label htmlFor="name" className="text-lg text-black">
                  Nome
                </label>
                <input
                  id="name"
                  name="name"
                  placeholder="Nome"
                  required
                  onChange={handleChange}
                  value={data.name}
                  className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
                />
              </div>
              <div className="space-y-2 flex-1 w-full">
                <label htmlFor="surname" className="text-lg text-black">
                  Cognome
                </label>
                <input
                  id="surname"
                  name="surname"
                  placeholder="Cognome"
                  required
                  onChange={handleChange}
                  value={data.surname}
                  className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-5 w-full">
              <div className="space-y-2 flex-1 w-full">
                <label htmlFor="city" className="text-lg text-black">
                  Città
                </label>
                <input
                  id="city"
                  name="city"
                  placeholder="Città"
                  required
                  onChange={handleChange}
                  value={data.city}
                  className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
                />
              </div>
              <div className="space-y-2 flex-1 w-full">
                <label htmlFor="phone" className="text-lg text-black">
                  Telefono
                </label>
                <input
                  id="phone"
                  name="phone"
                  placeholder="Telefono"
                  required
                  onChange={handleChange}
                  value={data.phone}
                  className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="email" className="text-lg text-black">
                Email
              </label>
              <input
                id="email"
                name="email"
                placeholder="Email"
                required
                onChange={handleChange}
                value={data.email}
                className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="institute" className="text-lg text-black">
                Parrocchia o Istituto di appartenenza
              </label>
              <input
                id="institute"
                name="institute"
                placeholder="Inserisci la tua Parrocchia o Istituto di appartenenza"
                required
                onChange={handleChange}
                value={data.institute}
                className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="message" className="text-lg text-black">
                Messaggio
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Lascia un messaggio qui"
                required
                rows="3"
                onChange={handleChange}
                value={data.message}
                className="p-3 rounded w-full outline-primary text-black bg-[#f9f9f9]"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className={`flex items-center justify-center mt-7 w-full bg-primary font-medium text-white py-3 rounded-lg hover:bg-black transition-all ${
                loading && "opacity-50 cursor-not-allowed"
              }`}
            >
              {loading ? "Caricamento..." : "Invia messaggio"}
            </button>
          </div>
        </form>
      </div>
      <div className="order-1 md:order-2 ">
        {/* Contact Image */}
        <img
          src="/assets/images/about/form.webp"
          className="h-auto md:w-[100%] object-cover"
          alt="Hero"
          width={1000}
          height={1000}
        />

        {/* Contact Info Box */}
        <div className=" mt-8">
          <div className="space-y-5">
            <div className="bg-[#f9f9f9] rounded-lg p-4">
              <div className="flex items-center gap-5">
                <div className="bg-[#edf6f4] p-4 rounded-full">
                  <div className="flex justify-center items-center">
                    <MailIcon />
                  </div>
                </div>
                <div>
                  <h1 className="font-semibold md:text-xl text-black">Email</h1>
                  <Link
                    to="mailto:info@easyoratorio.it"
                    className="text-[#71747D]"
                  >
                    info@easyoratorio.it
                  </Link>
                </div>
              </div>
            </div>
            <div className="bg-[#f9f9f9] rounded-lg p-4">
              <div className="flex items-center gap-5">
                <div className="bg-[#edf6f4] p-4 rounded-full">
                  <div className="flex justify-center items-center">
                    <LocationIcon />
                  </div>
                </div>
                <div>
                  <h1 className="font-semibold md:text-xl text-black">
                    INDIRIZZO
                  </h1>
                  <p className="text-[#71747D]">
                    via M. Rorato 4 30027 San Donà di Piave (VE)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
