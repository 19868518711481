import React, { useState, useRef } from "react";
import { Arrow1Icon, Arrow2Icon } from "../../utils/Icons";

function Characteristics() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [latestActiveIndex, setLatestActiveIndex] = useState(null);
  const imageRef = useRef(null);

  const images = [
    "./assets/images/about/Image-1.webp",
    "./assets/images/about/Image-2.webp",
    "./assets/images/about/Image-3.webp",
    "./assets/images/about/Image-4.webp",
    "./assets/images/about/Image-5.webp",
    "./assets/images/about/Image-6.webp",
  ];

  const toggleAccordion = (index) => {
    // Toggle accordion without scrolling the image into view
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
      setLatestActiveIndex(index);
    }
  };

  return (
    <div
      id="characteristics"
      className="grid md:grid-cols-2 grid-cols-1 items-start gap-5 lg:mt-16 mt-10 px-5 lg:px-14"
    >
      {/* Image Section with sticky positioning */}
      <div
        ref={imageRef}
        className="md:sticky md:top-16 flex md:justify-end justify-center order-1 md:order-2 transition-all duration-500"
      >
        <img
          src={
            latestActiveIndex !== null ? images[latestActiveIndex] : images[0]
          }
          className="h-auto md:w-[85%]"
          alt="Hero"
          width={1000}
          height={1000}
        />
      </div>

      {/* Accordion Section */}
      <div className="space-y-6 order-2 md:order-1">
        <h2 className="font-bold text-black xl:text-4xl lg:text-2xl text-xl">
          Qual’è il valore aggiunto di{" "}
          <span className="text-primary">EASYoratorio?</span>
        </h2>
        <div className="mt-16">
          <div className="w-full text-primary">
            {[
              {
                question: "INFORMAZIONI ISTANTANEE SUGLI UTENTI",
                answer: (
                  <>
                    Ogni utente all’interno di{" "}
                    <span className="italic"> EASYoratorio </span> possiede una
                    propria scheda anagrafica con tutte le informazioni relative
                    a residenza, contatti telefonici, eventi a cui è iscritto,
                    contenuto del proprio portafoglio virtuale, spese sostenute.
                    Tutte queste informazioni sono raggiungibili dagli
                    amministratori digitando semplicemente il nome dell’utente
                    oppure scansionando con lo smartphone il suo braccialetto
                    identificativo. Non dovrai più perdere tempo nel cercare su
                    lunghe liste i nomi degli iscritti, non dovrai perdere tempo
                    con lunghi appelli in pullman, ma tutto sarà veloce e
                    semplice a portata di smartphone.
                  </>
                ),
              },
              {
                question: "GESTIONE DEGLI ACCESSI E REGISTRO DELLE PRESENZE",
                answer: (
                  <>
                    La sicurezza, soprattutto nelle attività che prevedono la
                    presenza di minori, è un fattore fondamentale. Mantenere
                    controllato e limitato agli addetti l’accesso agli spazi di
                    animazione è di fondamentale importanza.{" "}
                    <span className="italic"> EASYoratorio </span> è stato
                    pensato per aiutare i responsabili a monitorare e verificare
                    gli accessi del personale. Grazie all’uso di pratici
                    braccialetti identificativi potrai regolare facilmente gli
                    accessi indicando orari e nomi delle persone autorizzate ad
                    aprire porte/cancelli. All’ingresso dei partecipanti
                    verranno scansionati i braccialetti di ogni iscritto (es.
                    bambini, animatori, adulti), i responsabili potranno avere a
                    portata di click le presenze giornaliere di tutti. Il
                    genitore, invece, potrà monitorare l’orario di
                    ingresso/uscita del proprio figlio.
                  </>
                ),
              },
              {
                question: "PAGAMENTI SENZA DENARO SOLO CON IL BRACCIALETTO",
                answer: (
                  <>
                    Le strutture di accoglienza parrocchiali spesso prevedono la
                    presenza di un BAR nel quale durante l’attività di
                    animazione i ragazzi possono acquistare snack, gelati,
                    bibite, etc. La gestione del BAR risulta spesso complessa
                    quando il numero degli utenti è alto: richiede velocità e
                    attenzione. EASY ha pensato proprio a te fornendoti la
                    possibilità di ridurre e semplificare il momento del
                    pagamento alla cassa, permettendo ai bambini di pagare senza
                    contanti, ma direttamente con il proprio braccialetto
                    identificativo. Gli operatori del BAR potranno così agire
                    con maggior velocità e precisione, senza dover maneggiare
                    denaro contante e ottenendo una veloce reportistica riguardo
                    i beni venduti e gli incassi nella giornata.
                  </>
                ),
              },
              {
                question: "PROMOZIONE FACILE DEGLI EVENTI",
                answer: (
                  <>
                    Perché un evento parrocchiale abbia successo è necessario
                    comunicare bene e in maniera accattivante con i nostri
                    utenti, attraverso i mezzi digitali come i social network
                    (WhatApp, Instagram, Facebook). Perché la pubblicazione
                    abbia efficacia deve esporre contenuti chiari, ordinati e
                    graficamente appetibili.{" "}
                    <span className="italic"> EASYoratorio </span> ha pensato a
                    tutto e senza che te ne accorga, genera in pochi istanti,
                    con i dati forniti sull’evento, una pagina web totalmente
                    dedicata dall’aspetto accattivante. La pagina è dotata di un
                    proprio link da copiare e incollare sui social e potrai
                    scaricare il relativo QR-code pronto per essere inserito
                    nella locandina dell’evento.
                  </>
                ),
              },
              {
                question: "COMUNICAZIONE IMMEDIATA CON GLI ISCRITTI",
                answer: (
                  <>
                    Chi si occupa di eventi sa bene quanto è importante
                    comunicare velocemente con i propri utenti. Quando i numeri
                    dei partecipanti iniziano ad essere importanti, l’azione del
                    comunicare deve rimanere veloce e precisa. Per questo EASY
                    ha scelto di integrare all’interno del gestionale un potente
                    sistema di comunicazione, che permette con molta facilità a
                    coloro che organizzano l’evento di inviare messaggi
                    istantanei a tutti gli iscritti. La medesima possibilità di
                    comunicare è offerta mediante l’invio di e-mail.
                  </>
                ),
              },
              {
                question: "SEMPLICE RENDICONTAZIONE ECONOMICA",
                answer: (
                  <>
                    Ogni evento a pagamento richiede sempre una attenta gestione
                    e rendicontazione dei flussi di denaro entrante e uscente
                    dalle casse dell’oratorio parrocchiale. Fare in modo che il
                    bilancio dell’evento possa risultare chiaro e trasparente è
                    l’obiettivo degli operatori amministrativi. Il loro lavoro
                    richiede notevole tempo, pazienza ed energie. Proprio per
                    questo EASY offre un modulo dedicato a supportare con
                    semplicità e senza alcuno sforzo la rendicontazione dei
                    flussi di denaro, che il sistema provvede a registrare
                    autonomamente. Così da offrire in qualsiasi momento la
                    possibilità di visionare lo stato di bilancio, poterlo
                    scaricare, stampare o modificare a piacimento.
                  </>
                ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="accordion-item rounded-2xl bg-primary mb-5 w-full transition-all duration-500"
              >
                <h2 className="accordion-header">
                  <button
                    className={`flex justify-between items-center w-full text-left p-3 md:text-lg text-sm rounded-2xl transition-all duration-300 ${
                      activeIndex === index
                        ? "text-white bg-primary"
                        : "bg-[#F2fbf9] text-[#0B0F1A] text-opacity-70"
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.question}
                    <span
                      className={`flex items-center justify-center rounded-3xl p-3 transition-all duration-300 ${
                        activeIndex === index ? "text-primary" : "text-gray-700"
                      }`}
                    >
                      {activeIndex === index ? (
                        <Arrow2Icon size={20} />
                      ) : (
                        <Arrow1Icon />
                      )}
                    </span>
                  </button>
                </h2>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    activeIndex === index
                      ? "max-h-[500px] opacity-100 translate-y-0"
                      : "max-h-0 opacity-0 -translate-y-2"
                  }`}
                >
                  <div className="p-3 transition-opacity duration-300">
                    <p className="text-white md:text-lg">{item.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Characteristics;
