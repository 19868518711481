import React from "react";

function Marquee() {
  const logos = [
    {
      src: "/assets/images/about/slide1.png",
      title: "Parr. San Giuseppe Lavoratore",
      description: "San Donà di Piave",
      link: "https://sangiuseppe.easyoratorio.it/",
    },
    {
      src: "/assets/images/about/slide2.png",
      title: "",
      description: "",
      link: "",
    },
    {
      src: "/assets/images/about/slide3.png",
      title: "",
      description: "",
      link: "",
    },
    {
      src: "/assets/images/about/slide4.png",
      title: "",
      description: "",
      link: "",
    },
    {
      src: "/assets/images/about/slide5.png",
      title: "",
      description: "",
      link: "",
    },
  ];

  return (
    <div
      id="XYZ"
      className="w-full bg-[#e7f9f7] overflow-hidden py-10 px-4 text-center"
    >
      <h2 className="text-secondary font-bold xl:text-3xl lg:text-2xl text-xl mb-10">
        Chi sta già utilizzando EASYoratorio?
      </h2>
      {/* Marquee Animation */}
      <div className="relative flex items-center">
        {/* First Animated Row */}
        <div className="animate-marquee flex whitespace-nowrap min-w-max">
          {[...logos, ...logos].map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-8 w-60 flex-shrink-0 text-center"
            >
              <div>
                <img
                  src={logo.src}
                  alt={`logo-${index}`}
                  className="object-contain w-full h-auto"
                />
                <h3 className="text-lg font-semibold mt-2">{logo.title}</h3>
                <p className="text-gray-500 text-sm">{logo.description}</p>
              </div>
            </a>
          ))}
        </div>

        {/* Second Animated Row (to create continuous effect) */}
        <div className="animate-marquee2 flex whitespace-nowrap min-w-max absolute top-0">
          {[...logos, ...logos].map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-8 w-60 flex-shrink-0 text-center"
            >
              <div>
                <img
                  src={logo.src}
                  alt={`logo-${index}`}
                  className="object-contain w-full h-auto"
                />
                <h3 className="text-lg font-semibold mt-2">{logo.title}</h3>
                <p className="text-gray-500 text-sm">{logo.description}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Marquee;
