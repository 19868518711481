import React, { useState, useEffect } from "react";

const slides = [
  {
    title: "Gestire gli eventi in oratorio non è mai stato così semplice.",
    description:
      "Organizza, promuovi e gestisci comodamente tutte le attività, con un unico semplice strumento.",
    image: "/assets/images/bg1.webp",
  },
  {
    title: "Tanti vantaggi offerti da un gestionale pensato apposta per te.",
    description:
      "Tutte le funzioni indispensabili per un’ottima amministrazione, per risparmiare tempo e frustrazioni.",
    image: "/assets/images/bg2.webp",
  },
  {
    title:
      "Dal tuo PC o dallo smartphone accedi ad uno strumento potente e indispensabile.",
    description: "Farai felici le famiglie della tua parrocchia!",
    image: "/assets/images/bg3.webp",
  },
];

export default function Hero() {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, 8000);
    return () => clearInterval(interval);
  }, [activeSlide]);

  const customLayouts = {
    1: (
      <div className="relative overflow-hidden min-h-screen flex items-center justify-center bg-[#FF7F00]">
        <div className="absolute inset-0 bg-black/40" />
        <div className="px-5 lg:px-14 relative z-10">
          <div className="grid grid-cols-2 gap-3 lg:gap-12 items-center">
            <div className="space-y-8 text-white">
              <h1 className="md:text-xl lg:text-4xl xl:text-6xl font-bold mb-4">
                {slides[activeSlide].title}
              </h1>
              <p className="md:text-2xl">{slides[activeSlide].description}</p>
            </div>
            <div className="mt-4">
              <img
                src={slides[activeSlide].image}
                className="w-[100%]"
                alt="Hero"
              />
            </div>
          </div>
        </div>
      </div>
    ),
    2: (
      <div className="relative overflow-hidden min-h-screen flex items-center justify-center bg-[#F20D68]">
        <div className="absolute inset-0 bg-black/40" />
        <div className="px-5 lg:px-14 relative z-10">
          <div className="grid grid-cols-2 gap-3 lg:gap-12 items-center">
            <div className="space-y-8 text-white">
              <h1 className="md:text-xl lg:text-4xl xl:text-6xl font-bold mb-4">
                {slides[activeSlide].title}
              </h1>
              <p className="md:text-2xl">{slides[activeSlide].description}</p>
            </div>
            <div className="md:flex md:justify-end lg:justify-end xl:justify-end mt-4">
              <img
                src={slides[activeSlide].image}
                className="md:w-[85%] w-[100%]"
                alt="Hero"
              />
            </div>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className="relative flex items-center justify-center overflow-hidden min-h-screen">
      {/** If activeSlide matches a key in customLayouts, use the custom layout; otherwise use the fallback */}
      {customLayouts[activeSlide] || (
        <>
          <div
            className="absolute inset-0 bg-cover bg-center bg-no-repeat transition-all duration-1000"
            style={{ backgroundImage: `url(${slides[activeSlide].image})` }}
          />
          <div className="absolute inset-0 bg-black/40" />
          <div className="max-w-6xl relative z-10 text-center text-white px-4 transition-opacity duration-700">
            <h1 className="text-3xl md:text-6xl font-bold mb-4">
              {slides[activeSlide].title}
            </h1>
            <p className="text-lg md:text-2xl">
              {slides[activeSlide].description}
            </p>
          </div>
        </>
      )}

      <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-3 z-20">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveSlide(index)}
            className={`h-3 w-3 rounded-full transition-all duration-300 ${
              activeSlide === index
                ? "w-8 bg-white"
                : "bg-white/50 hover:bg-white/75"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}
