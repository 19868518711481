export const DesignCopy = [
  {
    img: "./assets/images/icons/1.png",
    title: "ISCRIZIONI ONLINE",
    description:
      "Basta con lunghe code per iscriverti al GrEst! Con EASYoratorio ti potrai iscrivere comodamente da casa tua con lo smartphone. Potrai visualizzare in un istante tutti gli eventi, GrEst, gite, concerti, spettacoli che la tua parrocchia organizza. In un semplice click potrai riservare il tuo posto.",
  },
  {
    img: "./assets/images/icons/2.png",
    title: "ANAGRAFICA COMPLETA",
    description:
      "Dì addio alle lunghe liste cartacee, ai complicati elenchi su Excel. Ora gestisci tutto tramite un programma EASY, che ti permette di ottenere in pochi click tutte le informazioni di cui hai bisogno. Digita il nome del tuo utente e in un istante ogni informazione su di lui ti sarà più chiara.",
  },
  {
    img: "./assets/images/icons/3.png",
    title: "CONTROLLO ACCESSI",
    description:
      "Sappiamo quanto è delicato lavorare in un progetto educativo con minori. Quanto la privacy e la sicurezza siano oggetto delle nostre maggiori attenzioni. Con EASYoratorio puoi stare tranquillo! Chi può accedere agli ambienti del tuo GrEst? D’ora in avanti lo decidi tu, abilitando i singoli accessi.",
  },
  {
    img: "./assets/images/icons/4.png",
    title: "FACILI SQUADRE, GRUPPI, PULLMAN",
    description: `Gli eventi con tanti partecipanti chiedono sempre un’ottima organizzazione. C’è bisogno di preparare le liste delle squadre, dividere gli iscritti nei pullman. Basta a inutili frustrazioni! Affidati ad uno strumento facile e veloce per creare tutti i gruppi, le squadre, le liste dei pullman di cui hai bisogno.`,
  },
  {
    img: "./assets/images/icons/5.png",
    title: "PAGAMENTI ONLINE",
    description:
      "La gestione di contanti è diventata sempre più onerosa e rischiosa. La diffusione dei metodi di pagamento elettronici ci permette di trasferire in piena sicurezza denaro dalla carta di credito dei nostri utenti al nostro conto corrente parrocchiale. Con EASY ora puoi farlo anche tu! I tuoi iscritti potranno pagare velocemente e in piena sicurezza.",
  },
  {
    img: "./assets/images/icons/6.png",
    title: "COMUNICA I TUOI EVENTI",
    description:
      "Quanto è importante riuscire a comunicare bene il nostro evento. Quanta tempo e denaro ci costa comunicare con i nostri utenti. Adotta allora uno strumento come EASY, che senza alcuno sforzo organizza i dettagli del tuo evento e li presenta in una bella veste grafica. Lui crea per te una pagina web per ogni evento e ti fornisce il QR-code per la sua promozione.",
  },
  {
    img: "./assets/images/icons/7.png",
    title: "BILANCIO AUTOMATICO",
    description:
      "Organizzare un evento richiede di contabilizzazione con precisione i flussi di denaro in ingresso e in uscita. EASY viene in soccorso a questa tua esigenza! Senza che tu te ne accorga lui registra per te tutti i flussi di denaro e li organizza con precisione in un semplice bilancio da poter stampare direttamente o modificare a piacimento.",
  },
  {
    img: "./assets/images/icons/8.png",
    title: "APPELLO AUTOMATICO",
    description:
      "Lavorare con i ragazzi richiede attenzione e responsabilità. Verificare la presenza di tutti in occasione di spostamenti o di gite richiede molta precisione, per questo EASY ti fornisce uno strumento per controllare le presenze, senza tenere in mano liste cartacee, ma scansionando semplicemente il braccialetto personale di ogni bambino.",
  },
  {
    img: "./assets/images/icons/9.png",
    title: "PAGAMENTI AL BAR SENZA DENARO",
    description:
      "Ogni attività ricreativa che si rispetti offre la possibilità di un momento di ristoro al BAR. Sappiamo bene nel tempo di pausa quanta ressa c’è di fronte al bancone. Con EASY puoi semplificare tutto questo e liberare bambini e cassieri dall’uso dei contanti. Con il loro braccialetto personale ogni bambino paga veloce e senza preoccupazioni.",
  },
];
