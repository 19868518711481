import { Menu, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"; // React Router Link for external pages
import { Link as ScrollLink } from "react-scroll"; // React Scroll Link for smooth scrolling

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  // Toggle Mobile Menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Detect Scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Prevent body scroll when menu is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  // Scroll to Top Function
  const scrollToTop = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  
  const navLinks = [
    { title: "Funzionalità", href: "services", isScroll: true },
    { title: "il Valore aggiunto", href: "characteristics", isScroll: true },
    { title: "FAQ", href: "FAQ", isScroll: true },
    { title: "Contattaci", href: "Contact", isScroll: true },
  ];

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-white shadow-md text-black"
          : "bg-transparent text-white"
      }`}
    >
      <div className="px-5 lg:px-14">
        <div className="flex justify-between h-20 items-center">
          {/* Logo */}
          <Link
            to="/"
            onClick={scrollToTop}
            className="flex items-center gap-3"
          >
            <img
              src="/assets/images/logo.png"
              alt="Logo"
              width={60}
              height={60}
            />
            <h2 className="text-2xl font-extrabold tracking-wider drop-shadow-lg">
              <span className="text-[#ff7f02] drop-shadow-md animate-pulse inline-block">
                EASY
              </span>
              <span className="text-[#4fa0cb] drop-shadow-md italic inline-block">
                ora
              </span>
              <span className="text-[#f30e69] drop-shadow-md font-bold scale-110 inline-block">
                tor
              </span>
              <span className="text-[#ff7f02] drop-shadow-md italic animate-bounce inline-block">
                io
              </span>
            </h2>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden lg:flex lg:items-center lg:justify-center flex-1">
            <div className="flex space-x-7">
              {navLinks.map((link, index) =>
                link.isScroll ? (
                  <ScrollLink
                    key={index}
                    to={link.href}
                    smooth={true}
                    duration={900}
                    offset={-100} // Adjust position for better alignment
                    className="cursor-pointer"
                  >
                    {link.title}
                  </ScrollLink>
                ) : (
                  <Link key={index} to={link.href} className="cursor-pointer">
                    {link.title}
                  </Link>
                )
              )}
            </div>
          </div>

          {/* Join Button (Desktop) */}
          <div className="hidden lg:flex lg:items-center">
            <ScrollLink
              to="XYZ" // Change this to the actual ID of the section
              smooth={true}
              duration={900}
              offset={-100} // Adjust for navbar height
              className={`group relative overflow-hidden rounded-full px-8 py-3 font-bold shadow-lg transition-all duration-300 ease-in-out
  ${isScrolled ? "bg-primary text-white" : "bg-white text-primary"}
  hover:scale-105 hover:shadow-2xl active:scale-90
  border-2 ${isScrolled ? "border-primary" : "border-white"}
  hover:border-opacity-50 cursor-pointer block text-center`}
            >
              <span className="relative z-10">Chi lo sta usando?</span>

              {/* **Shiny Swipe Effect** */}
              <span className="absolute left-[-100%] top-0 h-full w-1/3 transform skew-x-[-20deg] bg-gradient-to-r from-transparent via-white/50 to-transparent opacity-40 transition-all duration-500 group-hover:left-[120%]"></span>

              {/* **Pulse Animation** */}
              <span className="absolute inset-0 rounded-full bg-white/10 opacity-0 group-hover:opacity-100 group-hover:animate-pulse"></span>

              {/* **Border Glow Effect** */}
              <span className="absolute inset-0 rounded-full border-2 border-transparent group-hover:border-white/30 group-hover:animate-glow"></span>
            </ScrollLink>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="lg:hidden p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={toggleMenu}
          >
            {isOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu
                className={`h-6 w-6 ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`lg:hidden fixed inset-0 z-50 transition-opacity duration-300 ease-in-out ${
          isOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="absolute inset-0 bg-gray-500/75"
          onClick={toggleMenu}
        ></div>
        <div
          className={`absolute inset-y-0 right-0 w-full max-w-xs bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between h-20 px-4 border-b border-primary">
            <Link to="/" className="flex items-center gap-3">
              <img
                src="/assets/images/logo.png"
                alt="Logo"
                width={55}
                height={55}
              />
              <h2 className="font-extrabold tracking-wide">
                <span className="text-[#ff7f02] drop-shadow-md">EASY</span>
                <span className="text-[#4fa0cb] drop-shadow-md italic">
                  ora
                </span>
                <span className="text-[#f30e69] drop-shadow-md">tor</span>
                <span className="text-[#ff7f02] drop-shadow-md italic">io</span>
              </h2>
            </Link>
            <button
              className="p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={toggleMenu}
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navLinks.map((link, index) =>
              link.isScroll ? (
                <ScrollLink
                  key={index}
                  to={link.href}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  className="block px-3 py-2 cursor-pointer rounded-md text-base font-medium text-black transition duration-300"
                  onClick={toggleMenu}
                >
                  {link.title}
                </ScrollLink>
              ) : (
                <Link
                  key={index}
                  to={link.href}
                  className="block px-3 py-2 cursor-pointer rounded-md text-base font-medium text-black  transition duration-300"
                  onClick={toggleMenu}
                >
                  {link.title}
                </Link>
              )
            )}
          </div>
          <div className="mt-4 px-4">
            <ScrollLink
              to="XYZ" // Change this to the actual ID of the section
              smooth={true}
              duration={900}
              offset={-100} // Adjust for navbar height
              className={`group w-full relative overflow-hidden rounded-full px-8 py-3 font-bold shadow-lg transition-all duration-300 ease-in-out
  ${isScrolled ? "bg-primary text-white" : "bg-primary text-white"}
  hover:scale-105 hover:shadow-2xl active:scale-90
  border-2 ${isScrolled ? "border-primary" : "border-primary"}
  hover:border-opacity-50 cursor-pointer block text-center`}
            >
              <span className="relative z-10">Chi lo sta usando?</span>

              {/* **Shiny Swipe Effect** */}
              <span className="absolute left-[-100%] top-0 h-full w-1/3 transform skew-x-[-20deg] bg-gradient-to-r from-transparent via-white/50 to-transparent opacity-40 transition-all duration-500 group-hover:left-[120%]"></span>

              {/* **Pulse Animation** */}
              <span className="absolute inset-0 rounded-full bg-white/10 opacity-0 group-hover:opacity-100 group-hover:animate-pulse"></span>

              {/* **Border Glow Effect** */}
              <span className="absolute inset-0 rounded-full border-2 border-transparent group-hover:border-white/30 group-hover:animate-glow"></span>
            </ScrollLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
