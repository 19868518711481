import React from "react";
import MainHero from "../components/Home/MainHero";
import ServicesLayout from "../components/Home/ServicesLayout";
import { DesignCopy } from "../components/Home/DesignCopy";
import AboutCompany from "../components/Home/AboutCompany";
import FAQ from "../components/Home/FAQ";
import Marquee from "../components/Home/Marquee";
import Footer from "../components/common/Footer";

function Home() {
  return (
    <>
      <MainHero />
      <ServicesLayout name={"Design Services"} copy={DesignCopy} />
      <AboutCompany />
      <FAQ />
      <Marquee />
      <Footer />
    </>
  );
}

export default Home;
