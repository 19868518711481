import { X } from "lucide-react"; // Import the X (cross) icon
import React, { useState } from "react";
import { ArrowIcon } from "../../utils/Icons";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="FAQ" className="lg:py-12 py-8 px-5 lg:px-14">
      <h2 className="text-center text-secondary font-extrabold xl:text-4xl lg:text-2xl text-xl">
        <span className="text-primary">Domande </span> Frequenti
      </h2>

      <div className="grid md:grid-cols-12 grid-cols-1 items-start gap-5 lg:mt-16 mt-10 transition-all duration-500">
        {/* FAQ Text Section */}
        <div className="md:col-span-7 flex flex-col justify-center order-2 md:order-1">
          <div className="w-full text-primary">
            {[
              {
                question:
                  "Che cosa comporta adottare lo strumento EASYoratorio?",
                answer: (
                  <>
                    All’atto della firma del contratto viene stabilito quali
                    tipologie di servizi acquistare, essi vengono individuati
                    all’interno del pacchetto di funzionalità offerte da{" "}
                    <span className="italic">EASYoratorio</span>. Una volta
                    definiti i singoli servizi verrà avviata la procedura per la
                    creazione di una nuova istanza di{" "}
                    <span className="italic">EASYoratorio</span> sui nostri
                    server alla quale si potrà accedere con un link
                    personalizzato:{" "}
                    <span className="italic">
                      https://&lt;nome_parrocchia&gt;.easyoratorio.it
                    </span>
                    .
                  </>
                ),
              },
              {
                question: "Chi si occupa dei server e dei backup?",
                answer: (
                  <>
                    Non dovrai preoccuparti di questioni tecniche, è a nostro
                    carico la gestione di tutta l’infrastruttura necessaria
                    affinché <span className="italic">EASYoratorio</span>{" "}
                    funzioni regolarmente e sia di supporto alle attività.
                  </>
                ),
              },
              {
                question: "Quali sono i costi per acquistare EASYoratorio?",
                answer: (
                  <>
                    <span className="italic">EASYoratorio</span> si acquista
                    versando una quota forfettaria all’atto della firma del
                    contratto. La quota dipende dal tipo e dal numero di servizi
                    acquistati. Il cliente otterrà una propria istanza del
                    prodotto con un proprio nome a dominio{" "}
                    <span className="italic">
                      https://&lt;nome_parrocchia&gt;.easyoratorio.it
                    </span>
                    .
                  </>
                ),
              },
              {
                question:
                  "Come vengono custoditi i dati sensibili degli iscritti? C’è qualche pericolo?",
                answer: (
                  <>
                    Il nostro sistema utilizza tutte le precauzioni necessarie e
                    possibili con le attuali tecnologie per proteggere i tuoi
                    dati. Nel caso di recesso del contratto, tutti i dati ti
                    saranno restituiti sotto forma a te più consona e cancellati
                    definitivamente dai nostri server.
                  </>
                ),
              },
              {
                question:
                  "Sono previsti degli aggiornamenti? Sono a pagamento?",
                answer: (
                  <>
                    L’applicazione <span className="italic">EASYoratorio</span>{" "}
                    al momento della vendita risulta aggiornata e stabile
                    secondo l’ultima versione disponibile. Gli aggiornamenti
                    correttivi saranno installati gratuitamente, mentre nuove
                    funzionalità potranno essere proposte gratuitamente o con un
                    costo concordato.
                  </>
                ),
              },
              {
                question:
                  "E’ possibile provare il prodotto prima di acquistarlo?",
                answer: (
                  <>
                    Assolutamente sì. Abbiamo creato un ambiente dimostrativo
                    (DEMO) dedicato ai nostri clienti nel quale poter
                    sperimentare con libertà di tempo e risorse tutte le
                    funzionalità che{" "}
                    <span className="italic">EASYoratorio</span> offre.
                    Contattaci direttamente per avere accesso alla demo.
                  </>
                ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="accordion-item rounded-2xl bg-primary mb-5 w-full transition-all duration-500"
              >
                <h2 className="accordion-header">
                  <button
                    className={`flex shadow-md justify-between items-center w-full text-left p-6 md:text-lg text-sm rounded-2xl transition-all duration-300 ${
                      activeIndex === index
                        ? "text-white bg-primary"
                        : "bg-[#FBFBFB] border-[1px] border-[#b3e5dc] text-[#0B0F1A] text-opacity-70"
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.question}
                    <span
                      className={`flex items-center justify-center rounded-3xl px-8 p-2 transition-all duration-300 ${
                        activeIndex === index
                          ? "bg-white text-primary"
                          : "bg-white border-[1px] border-[#d4d5d8] text-gray-700"
                      }`}
                    >
                      {activeIndex === index ? <X size={20} /> : <ArrowIcon />}
                    </span>
                  </button>
                </h2>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    activeIndex === index
                      ? "max-h-[500px] opacity-100 translate-y-0"
                      : "max-h-0 opacity-0 -translate-y-2"
                  }`}
                >
                  <div className="p-6 transition-opacity duration-300">
                    <p className="text-white md:text-lg">{item.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Image Section (Now adjusts height dynamically) */}
        <div className="md:col-span-5 flex items-center  justify-center md:justify-end md:order-2 order-1 transition-all duration-500">
          <img
            src="/assets/images/about/faq.webp"
            className="md:w-[80%] transition-all duration-500"
            alt="FAQ Illustration"
            width={1000}
            height={1000}
          />
        </div>
      </div>
    </section>
  );
}

export default FAQ;
