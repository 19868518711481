import React from "react";

function Footer() {
  return (
    <footer className="bg-primary">
      {/* <div className="px-5 lg:px-14">
        <h1 className="text-center text-white md:text-3xl text-xl font-semibold pt-7">
          Software House Name
        </h1>
        <div className="sm:hidden block">
          <div className="grid grid-cols-1 py-7">
            <ul className="space-y-2 mb-7">
              <li className="flex items-center gap-5">
                <h1 className="text-lg font-bold text-white">INDIRIZZO:</h1>
                <h1 className="text-lg text-white">
                  via M. Rorato 4 30027 San Donà di Piave (VE)
                </h1>
              </li>
              <li className="flex items-center gap-5">
                <h1 className="text-lg font-bold text-white">Website:</h1>
                <h1 className="text-lg text-white">www.easyoratorio.it</h1>
              </li>
            </ul>

            <div>
              <h3 className="md:text-2xl text-xl font-bold mb-3 text-white">
                Subscribe to Our Newsletter
              </h3>
              <div className="flex mb-4">
                <input
                  type="email"
                  placeholder="you@example.com"
                  className="flex-grow px-4 py-2 bg-transparent rounded-l-lg border-white text-white border-[1px] placeholder-white focus:outline-none"
                />
                <button className="bg-white text-primary px-4 py-2 rounded-r-lg">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          <div className="grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-20 py-10">
            <div>
              <ul className="space-y-2">
                <li className="flex items-center gap-3">
                  <h1 className="text-lg font-bold text-white">INDIRIZZO:</h1>
                  <h1 className="text-lg text-white">
                     via M. Rorato 4 30027 San Donà di Piave (VE)
                  </h1>
                </li>
                <li className="flex items-center gap-3">
                  <h1 className="text-lg font-bold text-white">Website:</h1>
                  <h1 className="text-lg text-white">www.easyoratorio.it</h1>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="md:text-2xl text-xl font-bold mb-3 text-white">
                Subscribe to Our Newsletter
              </h3>
              <div className="flex mb-4">
                <input
                  type="email"
                  placeholder="you@example.com"
                  className="flex-grow px-4 py-2 bg-transparent rounded-l-lg border-white text-white border-[1px] placeholder-white focus:outline-none"
                />
                <button className="bg-white text-primary px-4 py-2 rounded-r-lg">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="flex items-center justify-center py-5 border-t border-gray-50">
        <p className="text-white px-5 md:px-14 md:text-center">
          Copyright © 2025 easyoratorio.it. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
