import React from "react";
import Characteristics from "./Characteristics";
import HowWork from "./HowWork";
import About from "./About";
import Form from "./Form";
function AboutCompany() {
  return (
    <section className="lg:py-12 py-8">
      <About />
      <HowWork />
      <Characteristics />
      <Form />
    </section>
  );
}

export default AboutCompany;
