import React from "react";

function HowWork() {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-5 lg:mt-16 mt-10 px-5 lg:px-14 ">
      <div className="flex items-center md:justify-start justify-center  ">
        <img
          src="./assets/images/about/how.webp"
          className="h-auto md:w-[80%]"
          alt="Hero"
          width={1000}
          height={1000}
        />
      </div>
      <div className="space-y-6">
        <h2 className="font-bold text-black xl:text-4xl lg:text-2xl text-xl">
          Quali vantaggi per ragazzi e{" "}
          <span className="text-primary"> famiglie?</span>
        </h2>

        <p className=" text-black text-opacity-50 ">
          <span className="italic">EASYoratorio</span> è stato progettato con
          alcune funzionalità pensate in particolare{" "}
          <strong>per i genitori</strong>, perché possano essere{" "}
          <strong>facilmente e velocemente informati sulle attività.</strong> I
          genitori accedono dal proprio smartphone ad informazioni in tempo
          reale relative alle attività che stanno svolgendo i propri figli.
          Ricevono <strong>notifiche mirate</strong> ed istantanee, ad esempio
          per informare sull'orario previsto di rientro dalle gite.
          <span className="hidden md:inline">
            <br />
          </span>
          Durante gli orari di apertura dell’oratorio il tema della sicurezza
          dei ragazzi è di fondamentale importanza, per questo{" "}
          <span className="italic">EASYoratorio </span>
          dotando i singoli iscritti di un{" "}
          <strong>braccialetto identificativo</strong>, registra{" "}
          <strong>orari di ingresso e uscita di ogni singolo bambino.</strong>{" "}
          Inoltre, il sistema di controllo accessi permette solo a persone
          identificate ed autorizzate di poter accedere agli ambienti
          oratoriali.
          <span className="hidden md:inline">
            <br />
          </span>
          E’ apprezzata da molti genitori la funzionalità di{" "}
          <span className="italic"> EASYoratorio </span>
          relativa ai{" "}
          <strong>
            {" "}
            pagamenti online con carta di credito o{" "}
            <span className="italic"> Paypal </span>{" "}
          </strong>{" "}
          così da semplificare e velocizzare le iscrizioni degli eventi a
          pagamento. Questa funzione libera i figli dal maneggiare denaro
          contante permettendo di{" "}
          <strong>
            {" "}
            pagare il gelato al bar dell’oratorio usando semplicemente il
            braccialetto{" "}
          </strong>{" "}
          identificativo.
        </p>
      </div>
    </div>
  );
}

export default HowWork;
